<template>
  <div class="about">
    <h1 class="text-center" style="color: #072878;">{{ 'Sobre mí' }}</h1>
    <div class="d-flex justify-content-center mt-5">
      <div class="card mb-3" style="max-width: 900px;">
        <div class="row g-0">
          <div class="col-md-4">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/el-bicho-de-los-dibujos.appspot.com/o/About.jpg?alt=media&token=c5e4e1e9-9d70-4af6-b404-3de1347e9bf2" 
              alt="about" 
              class="img-fluid rounded-start"
            >
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Yo</h5>
              <p class="card-text justificado">
                Soy una persona introvertida, solamente hablo cuando considero necesario hacerlo pero si suelo sostener 
                conversaciones cuando hay temas de interés o requieren de mi opinión, con amigos o personas de confianza 
                suelo ser mas hablantín, me gusta la música tanto escucharla como tocar, también nací con el talento 
                para dibujar, de vez en cuando suelo escribir historias o novelas o como les guste llamarlas, espero algún 
                día poder publicarlas ya sea por escrito o en animación.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-group text-center justify-content-center">
      <div class="card mx-3" style="max-width: 500px;">
        <div class="row g-0">
          <div class="col-md-4">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/el-bicho-de-los-dibujos.appspot.com/o/programacion.png?alt=media&token=acb7735d-ac7f-41cf-90f4-adb0e006bb7f" 
              alt="programador" 
              class="img-fluid rounded-start"
            >
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Programador</h5>
              <p class="card-text justificado">
                Conocimientos en desarrollo web con framework basados en javascript.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mx-3" style="max-width: 500px;">
        <div class="row g-0">
          <div class="col-md-4">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/el-bicho-de-los-dibujos.appspot.com/o/dibujo.png?alt=media&token=d62f60ba-a7c6-4d25-be2c-3b39a83d8255" 
              alt="dibujante" 
              class="img-fluid rounded-start"
            >
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Dibujante</h5>
              <p class="card-text justificado">
                Talento para dibujar y el intento de escribir historias.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mx-3" style="max-width: 500px;">
        <div class="row g-0">
          <div class="col-md-4">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/el-bicho-de-los-dibujos.appspot.com/o/musica.png?alt=media&token=5d8285aa-1af4-4004-aa4c-0486de4241e0" 
              alt="músico" 
              class="img-fluid rounded-start"
            >
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Músico</h5>
              <p class="card-text justificado">
                Guitarrista, bajista, baterista y compositor; y quizá algún día pianista y violinista.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.justificado{
  text-align: justify !important;
}
</style>
